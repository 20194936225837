import { Component, input, output } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'dir-chart-toolbar',
  imports: [TooltipModule],
  templateUrl: './chart-toolbar.component.html',
  styleUrl: './chart-toolbar.component.scss'
})
export class ChartToolbarComponent {
    isAdmin = input<boolean>(false);
    depthIncrease = output();
    depthDecrease = output();
    center = output();
    zoomIn = output();
    zoomOut = output();
    zoomReset = output();
    settings = output();
}
