<div class="btn-group" role="group" aria-label="Chart Toolbar">
    <button
        class="btn btn-icon btn-secondary"
        (click)="depthIncrease.emit()"
        tooltip="Show another level"
        [delay]="300">
        <i class="ki-solid ki-plus-circle fs-1"></i>
    </button>
    <button class="btn btn-icon btn-secondary" (click)="depthDecrease.emit()" tooltip="Hide a level" [delay]="300">
        <i class="ki-solid ki-minus-circle fs-1"></i>
    </button>
    <button class="btn btn-icon btn-secondary" (click)="center.emit()" tooltip="Center" [delay]="300">
        <i class="ki-solid ki-right-left fs-1"></i>
    </button>
    <button class="btn btn-icon btn-secondary" (click)="zoomIn.emit()" tooltip="Zoom in" [delay]="300">
        <i class="bi bi-zoom-in fs-1"></i>
    </button>
    <button class="btn btn-icon btn-secondary" (click)="zoomOut.emit()" tooltip="Zoom out" [delay]="300">
        <i class="bi bi-zoom-out fs-1"></i>
    </button>
    <button class="btn btn-icon btn-secondary" (click)="zoomReset.emit()" tooltip="Reset zoom" [delay]="300">
        <i class="ki-solid ki-arrow-diagonal fs-1"></i>
    </button>
    @if (isAdmin()) {
    <button class="btn btn-icon btn-secondary" (click)="settings.emit()" tooltip="Chart settings" [delay]="300">
        <i class="ki-solid ki-gear fs-1"></i>
    </button>
    }
</div>
