<dir-chart-toolbar
    (depthDecrease)="changeDepth(-1)"
    (depthIncrease)="changeDepth(1)"
    (center)="center.set(center() + 1)"
    (zoomIn)="zoomIn()"
    (zoomOut)="zoomOut()"
    (zoomReset)="zoomReset()"
    (settings)="openChartSettings()"
    [isAdmin]="isAdmin()"></dir-chart-toolbar>
<ti-orgchart-chart-view
    [chartNodes]="chartNodes()"
    [chartMeta]="chartMeta"
    [style.height.px]="800"
    [maxDepth]="depth()"
    linkColor="var(--bs-primary)"
    [startingPositionId]="startingPositionId()"
    [pictureComponentProvider]="pictureComponentProvider"
    [separationSettings]="separationSettings"
    [iconStyle]="1"
    [center]="center()"
    (navigate)="navigate($event)"
    (information)="info($event)"
    (panZoom)="setPanZoom($event)"></ti-orgchart-chart-view>
