import { Injectable } from '@angular/core';
import { DataFromDb, DirectoryUser } from '../data/data.models';
import { from, Observable, of, map, mergeMap, tap, take, forkJoin } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { AppDB } from './db.service';
import { Category, CategoryItem, CustomizedCategoryItem, getDefaultCategories } from '../data/categories';
import { getRandomCityItemUrl } from '../data/cities';
import { GeoPattern } from 'src/lib/geopattern';
import { modifyAndFilterUsers } from '../utils/category.utils';
import { DataServiceProvider } from './data-service.provider';

@Injectable({ providedIn: 'root' })
export class CategoriesService {
    constructor(
        private store: Store,
        private db: AppDB,
        private dataServiceProvider: DataServiceProvider
    ) {}

    rebuildCategoriesFromDb() {
        console.log('rebuild categories from db');
        return forkJoin([
            this.dataServiceProvider
                .getDataService()
                .getData()
                .pipe(
                    tap((x) => console.log('got users', x)),
                    take(1)
                ),
            this.loadCategories(),
            this.loadCategoryItems(),
            this.db.getFilters(),
            this.db.getFavorites(),
            this.db.getUserColumnSettings(),
            this.db.getUserModifications()
        ]).pipe(
            tap((x) => console.log('forkjoin result', x)),
            mergeMap(
                ([
                    { users, dataSourceType },
                    savedCategories,
                    savedCategoryItems,
                    filters,
                    favorites,
                    userColumnSettings,
                    userModifications
                ]) => {
                    const filteredUsers = modifyAndFilterUsers(users, filters, userModifications);
                    console.log('calling rebuild categories');
                    return this.rebuildCategories(filteredUsers, savedCategories, savedCategoryItems).pipe(
                        map<Category[], DataFromDb>((categories) => ({
                            users,
                            dataSourceType,
                            filteredUsers,
                            categories,
                            savedCategories,
                            savedCategoryItems,
                            userColumnSettings,
                            userModifications,
                            filters,
                            favorites
                        }))
                    );
                }
            )
        );
    }

    rebuildCategoriesFromDbWithUsers() {
        console.log('rebuild categories from db with users already loaded');
        return forkJoin([
            //careful! take(1) is needed because selecting from store does not complete the observable,
            //so forkjoin will never complete
            this.store.pipe(select(fromRoot.selectUsersRaw)).pipe(take(1)),
            this.store.pipe(select(fromRoot.selectDataSourceType)).pipe(take(1)),
            this.loadCategories(),
            this.loadCategoryItems(),
            this.db.getFilters(),
            this.db.getFavorites(),
            this.db.getUserColumnSettings(),
            this.db.getUserModifications()
        ]).pipe(
            mergeMap(
                ([
                    users,
                    dataSourceType,
                    savedCategories,
                    savedCategoryItems,
                    filters,
                    favorites,
                    userColumnSettings,
                    userModifications
                ]) => {
                    //debugger;
                    const filteredUsers = modifyAndFilterUsers(users, filters, userModifications);
                    console.log('calling rebuild categories with users');
                    return this.rebuildCategories(filteredUsers, savedCategories, savedCategoryItems).pipe(
                        map<Category[], DataFromDb>((categories) => ({
                            users,
                            dataSourceType,
                            filteredUsers,
                            categories,
                            savedCategories,
                            savedCategoryItems,
                            userColumnSettings,
                            userModifications,
                            filters,
                            favorites
                        }))
                    );
                }
            )
        );
    }

    rebuildCategories(
        users: DirectoryUser[],
        savedCategories: Category[],
        savedCategoryItems: CustomizedCategoryItem[]
    ) {
        if (savedCategories.length === 0) {
            console.warn('no saved categories found, creating default categories');
            savedCategories = getDefaultCategories();
        }
        console.log('rebuilding categories called with savedCategories count: ' + savedCategories.length);
        let categoryItemId = 1;
        //debugger;
        const savedCategoriesWithItems = savedCategories.map((category) => {
            const categoryItems = users
                .map((user) => user[category.fieldName])
                .filter((value, index, arr) => arr.indexOf(value) === index && value != null && value != '')
                .map((name) => {
                    let categoryItem: CategoryItem = {
                        id: categoryItemId++,
                        categoryId: category.id,
                        fieldName: category.fieldName,
                        fieldValue: name,
                        name,
                        timestamp: Date.now(),
                        people: users.filter(
                            (u) =>
                                u[category.fieldName] != null &&
                                u[category.fieldName].toLowerCase().trim() === name.toLowerCase().trim()
                        )
                    };
                    const savedCategoryItem = savedCategoryItems.find(
                        (x) => x.fieldValue === categoryItem.fieldValue && x.fieldName === categoryItem.fieldName
                    );
                    if (savedCategoryItem != null) {
                        categoryItem = {
                            ...categoryItem,
                            ...savedCategoryItem,
                            id: categoryItem.id,
                            categoryId: categoryItem.categoryId,
                            fieldName: categoryItem.fieldName,
                            fieldValue: categoryItem.fieldValue,
                            timestamp: categoryItem.timestamp,
                            people: categoryItem.people,
                            columnValues: { ...savedCategoryItem.columnValues }
                        };
                    }
                    if (
                        categoryItem.pattern == null &&
                        Object.keys(category.patternStrings ?? {}).includes(categoryItem.name.toLowerCase())
                    ) {
                        categoryItem.pattern = category.patternStrings[categoryItem.name.toLowerCase()];
                    }
                    if (categoryItem.pictureUrl == null && category.defaultPictureSetKey != null) {
                        categoryItem.pictureUrl = getRandomCityItemUrl(categoryItem.name);
                    }
                    if (!categoryItem.patternUrl && categoryItem.pattern && !categoryItem.pictureUrl) {
                        categoryItem.patternUrl = new GeoPattern(categoryItem.pattern).toDataUri();
                    }
                    if (categoryItem.pictureUrl == null && categoryItem.patternUrl == null) {
                        categoryItem.pattern = categoryItem.name + Date.now();
                        categoryItem.patternUrl = new GeoPattern(categoryItem.pattern).toDataUri();
                    }
                    return categoryItem;
                });
            //get pattern from category items and put into patternStrings in category, patternStrings is an object with keys as categoryItem names and values as pattern strings
            category.patternStrings = categoryItems.reduce((acc, item) => {
                if (item.pattern != null) {
                    acc[item.name.toLowerCase()] = item.pattern;
                }
                return acc;
            }, {});

            return { ...category, items: categoryItems };
        });
        return from(
            this.db.instance.transaction('rw', this.db.instance.categories, this.db.instance.savedCategories, () => {
                this.db.instance.categories.clear();
                this.db.instance.savedCategories.clear();
                this.db.instance.savedCategories.bulkPut(
                    savedCategories.map((x) => ({
                        ...x,
                        patternStrings:
                            savedCategoriesWithItems.find((y) => y.id === x.id)?.patternStrings ?? x.patternStrings
                    }))
                );
                this.db.instance.categories.bulkPut(savedCategoriesWithItems);
                const result = this.db.instance.categories.toArray();

                return result;
            })
        );
    }

    loadDataFromDb(): Observable<DataFromDb> {
        return forkJoin([
            this.dataServiceProvider.getDataService().getData().pipe(take(1)),
            this.db.getFilters(),
            this.db.getFavorites(),
            this.db.getCategories(),
            this.db.getSavedCategories().pipe(
                map((savedCategories) => {
                    if (savedCategories == null || savedCategories.length === 0) {
                        console.log('no saved categories, using default categories');
                        return getDefaultCategories();
                    }
                    return savedCategories;
                })
            ),
            this.db.getSavedCategoryItems(),
            this.db.getUserColumnSettings(),
            this.db.getUserModifications()
        ]).pipe(
            map(
                ([
                    { users, dataSourceType, isRefreshNeeded },
                    filters,
                    favorites,
                    categories,
                    savedCategories,
                    savedCategoryItems,
                    userColumnSettings,
                    userModifications
                ]) => {
                    return {
                        users,
                        dataSourceType,
                        isRefreshNeeded,
                        filteredUsers: modifyAndFilterUsers(users, filters, userModifications),
                        categories,
                        savedCategories,
                        savedCategoryItems,
                        userColumnSettings,
                        userModifications,
                        filters,
                        favorites
                    };
                }
            )
        );
    }

    loadCategories(): Observable<Category[]> {
        console.log('load categories called on categories service');
        return this.db.getSavedCategories().pipe(
            mergeMap((categories) => {
                console.log('loaded categories from db, count: ' + categories.length);
                if (!categories || categories.length === 0) {
                    console.log('no categories in db, reading from settings');
                    console.log('no categories in settings, returning default categories');
                    return of(getDefaultCategories().map((x) => ({ ...x })));
                }
                return of(categories);
            })
        );
    }

    loadCategoryItems(): Observable<CustomizedCategoryItem[]> {
        console.log('load category items called on categories service');
        return this.db.getSavedCategoryItems().pipe(
            mergeMap((savedCategoryItems) => {
                console.log('loaded categoryitems from db, count: ' + savedCategoryItems.length);
                if (!savedCategoryItems || savedCategoryItems.length === 0) {
                    console.log('no categoryItems in db, reading from settings');
                    return of([]);
                }
                return of(savedCategoryItems);
            })
        );
    }

    saveCategoryItem(categoryItem: CustomizedCategoryItem) {
        console.log('save category item called on categories service');//, categoryItem);
        return this.db.getSavedCategoryItems().pipe(
            mergeMap((savedCategoryItems) => {
                console.log('saving category item');//, categoryItem, savedCategoryItems);
                const existing = savedCategoryItems.find(
                    (x) => x.fieldValue === categoryItem.fieldValue && x.fieldName === categoryItem.fieldName
                );
                const itemToSave: CustomizedCategoryItem = { ...categoryItem, people: null };
                //make sure itemToSave has a unique id, just make it the biggest id + 1
                const maxId = savedCategoryItems.reduce((acc, item) => (item.id > acc ? item.id : acc), 0);
                itemToSave.id = maxId + 1;
                if (existing == null) {
                    console.log('saving new category item', itemToSave);
                    return of([...savedCategoryItems, itemToSave]);
                } else {
                    console.log('updating existing category item');//, itemToSave);
                    return of(
                        savedCategoryItems.map<CustomizedCategoryItem>((x) => (x.id === existing.id ? itemToSave : x))
                    );
                }
            })
        );
    }
}
