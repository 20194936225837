import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, map, switchMap } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppDB } from './db.service';
import { SettingsDataMap, SettingsServerResponse } from '../settings/settings.models';
import { Metadata } from '../data/data.models';
import { DataSource } from '../app.models';
import * as fromRoot from '../reducers';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    private SettingsUrl = '/api/settings';
    private DataSourceUrl = '/api/datasource';
    constructor(
        private http: HttpClient,
        private store: Store,
        private db: AppDB
    ) {}

    private getIsDemo() {
        return localStorage.getItem('isDemo') === 'true'; // ? '/api/demosettings' : '/api/settings';
    }

    private buildQueryParams = (metadata: Metadata): string => {
        if (!metadata?.slices) {
            return '';
        }
        return Object.entries(metadata.slices)
            .map(([key, { unixTimestamp }]) => `${key}=${unixTimestamp ?? 0}`)
            .join('&');
    };

    loadSettings(metadata: Metadata) {
        return this.store.pipe(
            select(fromRoot.selectDataSourceInfo),
            switchMap((dataSourceInfo) => {
                const queryParams = this.buildQueryParams(metadata);
                return this.http
                    .get<SettingsServerResponse>(
                        `${this.SettingsUrl}?dataSourceId=${dataSourceInfo.dataSourceId}&chartId=${dataSourceInfo.chartId}&${queryParams}`,
                        { observe: 'response' }
                    )
                    .pipe(this.processTimestamp);
            })
        );
    }

    saveSettingsSlice<K extends keyof SettingsDataMap>(
        sliceKey: K,
        data: SettingsDataMap[K],
        metadata: Metadata,
        signalRConnectionId?: string
    ): Observable<SettingsServerResponse> {
        return this.store.pipe(
            select(fromRoot.selectDataSourceInfo),
            switchMap(({ dataSourceId, chartId }) => {
                const unixTimestamp = metadata.slices[sliceKey]?.unixTimestamp ?? 0;
                let fullUrl = `${this.SettingsUrl}/${sliceKey}?unixTimestamp=${unixTimestamp}&dataSourceId=${dataSourceId}`;
                if (chartId) {
                    fullUrl += `&chartId=${chartId}`;
                }
                return this.http
                    .post<SettingsServerResponse>(fullUrl, data, { observe: 'response' })
                    .pipe(this.processTimestamp);
            })
        );
    }

    resetSettings() {
        return this.store.pipe(
            select(fromRoot.selectSelectedDataSourceId), // replace selectDataSourceId with the actual selector for dataSourceId
            switchMap((dataSourceId) =>
                this.http
                    .delete<SettingsServerResponse>(`${this.SettingsUrl}?dataSourceId=${dataSourceId}`, {
                        observe: 'response'
                    })
                    .pipe(this.processTimestamp)
            )
        );
    }

    createDataSource(dataSource: DataSource) {
        return this.http.post(`${this.DataSourceUrl}`, dataSource);
    }

    updateDataSource(dataSource: DataSource) {
        return this.http.put(`${this.DataSourceUrl}/${dataSource.id}`, dataSource);
    }

    removeDataSource(id: number) {
        return this.http.delete(`${this.DataSourceUrl}/${id}`);
    }

    private processTimestamp = map((response: HttpResponse<SettingsServerResponse>) => {
        let settings = response.body.settings?.value;
        if (settings == null || Object.keys(settings).length === 0) {
            settings = {
                categories: [],
                categoryItemsByCategory: {},
                userColumnSettings: { grid: [], details: [], table: [], chart: [] }
            };
            return response.body;
        }
        const date = response.body.settings?.timestamps?.unixTimestamp ?? 0; //response.headers.get('last-modified');
        const dateObj = new Date(date);
        console.log('Date from server: ' + dateObj);
        response.body.settings.value = settings;
        return response.body;
    });
}
